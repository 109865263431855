import { useEffect } from 'react';
import { checkIOS } from '../../util/ios';
import { isDev } from '../../util/urlHelpers';
import { useSelector } from 'react-redux';
// import { useOneSignal } from '../../services/oneSignal';

/** Listen to a permission granted event dispatched from Ios app */
export const useIosWvPermissionGranted = ({ callback = () => null, errorHandler = () => null }) => {
    const currentUser = useSelector(s => s.user.currentUser);
    // const oneSignal = useOneSignal();
    const currentUserId = currentUser?.id?.uuid;
    const { isNative } = checkIOS();

    useEffect(() => {
        if (!currentUserId) return;
        if (!isNative) return;

        /** For WebView only, logged in only */
        const handleUserPermissionGranted = async e => {
            try {
                const messageData = typeof e.data === 'string' ? JSON.parse(e.data) : {};
                const shouldHandleMessage = messageData && String(messageData.isGranted) === 'true';

                if (shouldHandleMessage) {
                    /** Update OS data when a user grants his permission */
                    // const { error, message } = await oneSignal.loginViaOnesignalId(
                    //     messageData.OnesignalId
                    // );
                    callback();
                }
            } catch (e) {
                if (isDev()) {
                    console.log('handleUserPermissionGranted.error:', e.message);
                }
                errorHandler();
            }
        };

        document.addEventListener('message', handleUserPermissionGranted);
        window.addEventListener('message', handleUserPermissionGranted);

        return () => {
            document.removeEventListener('message', handleUserPermissionGranted);
            window.removeEventListener('message', handleUserPermissionGranted);
        };
    }, [currentUserId]);
};
