import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { notifyIosAppOnLogIn } from '../../util/ios';
import { useOneSignal } from '../../services/oneSignal';
/** Show OneSignal banner to a user on successful login via idp */
export const useIdpAuthSuccess = () => {
    const currentUser = useSelector(s => s.user.currentUser);
    const currentUserId = currentUser?.id?.uuid;
    const oneSignal = useOneSignal();

    const handleIdpAuthSuccess = async () => {
        try {
            /**
             * For native app a user is redirected to Landing page
             * instead /idp-auth-success page as it works for the web
             */
            const idpAuthSuccess = Cookies.get('st-authsuccess')
                ? JSON.parse(Cookies.get('st-authsuccess').replace('j:', ''))
                : null;

            if (!idpAuthSuccess) return;

            const { isEnabled } = await oneSignal.isSubscriptionsEnabled();

            if (!isEnabled && idpAuthSuccess && idpAuthSuccess.isNative) {
                /** send a message to IOS native app on successful login via idp */
                return notifyIosAppOnLogIn(currentUser);
            }

            // if (isEnabled && idpAuthSuccess && idpAuthSuccess.isNative) {
            //     /** login a user to OS system */
            //     await oneSignal.loginViaSubscriptionId(subscriptions);
            // }
        } catch (e) {
            // do nothing
        } finally {
            Cookies.remove('st-authsuccess');
        }
    };

    useEffect(() => {
        if (!currentUserId) return;

        handleIdpAuthSuccess();
    }, [currentUserId]);
};
